/* You can add global styles to this file, and also import other style files */
@import 'theme';

body {
  background-color: $background-color;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}
.bold {
  font-weight: bold;
}
.upper {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
.primary {
  background-color: $primary-color;
  color: $primary-text-color;
}
.primary-inverted {
  color: $primary-color;
  background-color: $primary-text-color;
}
.secondary {
  background-color: $secondary-color;
  color: $secondary-text-color;
}
.default {
  background-color: $default-color;
  color: $default-text-color;
}
.title {
  font-size: 50px;
  font-weight: 800;
}
.subtitle {
  font-size: 37px;
}
.description {
  font-size: 22px;
}
.panel {
  &.rounded-border {
    border-radius: 20px;
  }
  &.page-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 1200px) {
      height: 752px;
    }
    .title {
      font-size: 50px;
      font-weight: 800;
      padding-bottom: 20px;
    }
    .subtitle {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 20px;
    }
    &.light {
      color: $secondary-text-color;
    }
    &.dark {
      color: $primary-text-color;
    }
  }
}
.page-info {
  .title {
    font-size: 50px;
    font-weight: 800;
  }
  .subtitle {
    font-size: 37px;
  }
  .description {
    font-size: 22px;
  }
  .subdescription {
    font-size: 18px;
  }
}
::ng-deep .subscription-modal {
  @media (min-width: 576px) {
    max-width: 670px;
  }
}
