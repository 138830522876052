$primary: #308fff;
$text-color-dark: #000;
$text-color-light: #fff;
$gray-light: #f3f3f2;
$background-color: $gray-light;
$footer-background-color: #231f20;
$font-family-sans-serif: 'Mulish', Helvetica, sans-serif;

$line-height-base: 1.2;
// button style
$btn-border-radius: 30px;
$btn-font-size: 0.75rem;
$btn-padding-x: 1rem;
// input style
$input-border-radius: 30px;
$input-border-color: $primary;
$input-placeholder-color: $primary;
$input-font-size: 15px;

// default
$default-color: $gray-light;
$default-text-color: $text-color-dark;
// primary
$primary-color: $primary;
$primary-text-color: $text-color-light;
// secondary
$secondary-color: $text-color-light;
$secondary-text-color: $text-color-dark;

$primaries: (
  'primary-100': rgba($primary, 0.3),
  'primary-200': rgba($primary, 0.5),
  'primary-300': rgba($primary, 0.7),
  'primary-400': rgba($primary, 0.85),
  'primary-500': $primary,
);
