@import 'variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

//  button-variant(
//   $background,
//   $border,
//   $color: color-contrast($background),
//   $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
//   $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
//   $hover-color: color-contrast($hover-background),
//   $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
//   $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
//   $active-color: color-contrast($active-background),
//   $disabled-background: $background,
//   $disabled-border: $border,
//   $disabled-color: color-contrast($disabled-background)
.btn-primary {
  //btn blu con bordo blu e testo bianco
  @include button-variant(
    $primary-color,
    $primary-color,
    $primary-text-color,
    $disabled-color: $primary-text-color
  );
}
.btn-primary-variant {
  //btn blu con bordo bianco e testo bianco
  @include button-variant(
    $primary-color,
    $primary-text-color,
    $primary-text-color
  );
}
.btn-primary-variant-transparent {
  //btn trasparente con bordo bianco e testo bianco
  @include button-variant(
    transparent,
    $primary-text-color,
    $primary-text-color
  );
}
.btn-primary-inverted {
  //btn bianco con bordo bianco e testo blu
  @include button-variant(
    $primary-text-color,
    $primary-text-color,
    $primary-color
  );
}
.btn-primary-inverted-variant {
  //btn bianco con bordo blu e testo blu
  @include button-variant(
    $primary-text-color,
    $primary-color,
    $primary-color,
    $hover-color: $primary-color
  );
}
.btn-primary-inverted-variant-dark {
  //btn bianco con bordo blu e testo nero
  @include button-variant(
    $primary-text-color,
    $primary-color,
    $text-color-dark,
    $hover-background: map-get($primaries, 'primary-100'),
    $hover-color: $secondary-text-color
  );
}
.btn-secondary {
  //btn bianco con bordo bianco e testo nero
  @include button-variant(
    $secondary-color,
    $secondary-color,
    $secondary-text-color,
    $hover-background: map-get($primaries, 'primary-100'),
    $hover-color: $secondary-text-color
  );
}

.toast-success {
  background-color: $primary-color;
  border-top: solid 10px #51a351;
}

.toast-info {
  background-color: $primary-color;
  border-top: solid 10px #2f96b4;
}

.toast-warning {
  background-color: $primary-color;
  border-top: solid 10px #f89406;
}

.toast-error {
  background-color: $primary-color;
  border-top: solid 10px #bd362f;
}

.toast-title {
  color: #fff;
}

.toast-message {
  color: #fff;
}

.toast-message-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
